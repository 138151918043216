<template>
  <div>
    <vx-card class="mb-4">
      <div class="flex flex-col gap-4">
        <div class="pb-8">
          <div class="flex flex-col gap-2">
            <div class="w-full">
              <select-suggestion
                :max="20"
                :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
                column="name,description"
                model="ContentQuestionnaire"
                :label="$t('Questionnaire')"
                v-model="questionnaire"
                ref="select_questionnaire"
                placeholderText="Digite o nome..."
                @clearSelection="clearData"/>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('questionnaire_id')">{{ errors.first('questionnaire_id') }}</span>
            </div>
          </div>
        </div>
      </div>
      <questionnaire-information v-if="questionnaire" :questionnaire="questionnaire"/>
      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <vs-button
            class="float-right mr-2"
            @click="loadData"
            :disabled="!validateForm">{{ $t('action.consult') }}</vs-button>
        </div>
      </div>
    </vx-card>

    <vs-tabs>
      <vs-tab :label="$t('backoffice.results_title')" v-if="enableTabQuestionnaire">
        <vx-card
            ref="results"
            class="mb-4">
            <div>
              <Grid
                ref="answersGrid"
                :service="questionnaireAnswerService"
                route_name="questionnaires_answers"
                :route_grid_path="answersResultsPath"
                order_column="finished_at"
                direction="desc"
                :fetchOnMounted="false"
                :showTotalText="'Total'">
                <template v-slot:gridThead>
                  <vs-th key="id" sort-key="id">ID</vs-th>
                  <vs-th key="user_name" sort-key="user_name">{{ $t('usuario') }}</vs-th>
                  <vs-th key="user_email" sort-key="user_email">{{ $t('email') }}</vs-th>
                  <vs-th key="created_at" sort-key="created_at">{{ $t('data-hora-inicio') }}</vs-th>
                  <vs-th key="finished_at" sort-key="finished_at">{{ $t('data-hora-termino') }}</vs-th>
                  <vs-th key="score" sort-key="score">{{ $t('points') }}</vs-th>
                </template>
                <template v-slot:gridData="props">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data" :class="`${tr.finished_at === null ? 'text-warning' : ''}`">
                    <vs-td key="id">{{ tr.id }}</vs-td>
                    <vs-td key="user_name">{{tr.user_name}}</vs-td>
                    <vs-td key="user_email">{{tr.user_email}}</vs-td>
                    <vs-td key="created_at">
                        <div v-html="createdFormated(tr)"></div>
                    </vs-td>
                    <vs-td key="finish_at">
                      {{tr.finished_at_formatted}}
                    </vs-td>
                    <vs-td key="score" class="flex items-center justify-center">{{formatScore(tr.score)}}</vs-td>
                    <vs-td width="10%">
                      <div class="flex items-center justify-center">
                        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                          <feather-icon
                            type="filled"
                            icon="MoreVerticalIcon">
                          </feather-icon>
                          <vs-dropdown-menu>
                            <vs-dropdown-item v-if="!tr.finished_at">
                              <div class="flex"
                                style="width: 150px;"
                                @click="confirmForceFinish(tr)" v-permission="'backoffice.questionnaire.force_finish'">
                                <div class="w-2/12">
                                  <feather-icon
                                  icon="CheckCircleIcon"
                                  svgClasses="text-success h-5 w-5 mb-1 stroke-current"/>
                                </div>
                                <div class="w-10/12">
                                  <div class="flex bg-gray-200 float-left">
                                    {{ $t('action.force_finish') }}
                                  </div>
                                </div>
                              </div>
                            </vs-dropdown-item>
                            <vs-dropdown-item v-if="tr.finished_at">
                              <div class="flex"
                                style="width: 150px;"
                                @click="confirmResetAnswer(tr)" v-permission="'backoffice.questionnaire.rewinded'">
                                <div class="w-2/12">
                                  <feather-icon
                                  icon="RepeatIcon"
                                  svgClasses="text-success h-5 w-5 mb-1 stroke-current"/>
                                </div>
                                <div class="w-10/12">
                                  <div class="flex bg-gray-200 float-left">
                                    {{ $t('action.second_chance') }}
                                  </div>
                                </div>
                              </div>
                            </vs-dropdown-item>
                            <vs-dropdown-item>
                              <div class="flex"
                                style="width: 150px;"
                                @click="confirmResetAnswerToBlank(tr)" v-permission="'backoffice.questionnaire.rewinded.blank'">
                                <div class="w-2/12">
                                  <feather-icon
                                  icon="RepeatIcon"
                                  svgClasses="text-danger h-5 w-5 mb-1 stroke-current"/>
                                </div>
                                <div class="w-10/12">
                                  <div class="flex bg-gray-200 float-left">
                                    {{ $t('action.second_chance') }} - zerar respostas!
                                  </div>
                                </div>
                              </div>
                            </vs-dropdown-item>
                          </vs-dropdown-menu>
                        </vs-dropdown>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </Grid>
            </div>
        </vx-card>
      </vs-tab>
      <vs-tab :label="$t('questionnaire.convidados')" v-if="enableTabInvited">
        <vx-card
          class="mb-4">
          <PlanUsageAlert target="participant_questionnaire"/>
          <h5 class="mt-6 border-bottom">{{ $t('convidar-participantes') }}</h5>

            <div class="vx-row flex items-end mt-4">
              <div class="vx-col w-full mb-2">
                <emails-input
                  ref="emailsInput"
                  @tagsSent="addEmails"
                  @importStudents="importStudents"
                  buttonText="Adicionar"
                  class="tour-publication-6">
                </emails-input>
              </div>
            </div>
          <div>
            <Grid ref="invitedGrid"
                  :multipleSelect="true"
                  @changedSelection="changedSelectionInvited"
                  :service="questionnaireService"
                  :route_grid_path="invitedPath"
                  order_column="email"
                  direction="asc"
                  :fetchOnMounted="false"
                  :showTotalText="'Total'"
                  :column_formats="{
                    'status': (val) => $t(`questionnaire.invite.status.${val}`)
                  }"
                  :css_formats="{
                    status: (val) => {
                        switch (val) {
                          case (null):
                            return ''
                          case ('p'):
                            return 'text-warning'
                          case ('o'):
                            return 'text-success'
                          case ('e'):
                            return 'text-danger'
                          case ('s'):
                            return ''
                          default:
                            return ''
                          }
                        }
                  }">
                <template v-slot:actions="item">
                  <div class="flex items-center justify-center">
                    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                      <feather-icon
                        type="filled"
                        icon="MoreVerticalIcon">
                      </feather-icon>
                      <vs-dropdown-menu>
                        <vs-dropdown-item v-if="enableResendAction">
                          <div class="flex"
                            style="width: 150px;"
                            @click="resendInvite(item.row)">
                            <div class="w-2/12">
                              <feather-icon
                                icon="SendIcon"
                                svgClasses="text-success h-5 w-5 mb-1 stroke-current"/>
                            </div>
                            <div class="w-10/12">
                              <div class="flex bg-gray-200 float-left">
                                {{ $t('action.resend_invite') }}
                              </div>
                            </div>
                          </div>
                        </vs-dropdown-item>
                        <vs-dropdown-item v-if="enableRemoveInviteAction && (item.row.status === 'p' || item.row.status === 'e')">
                          <div class="flex"
                            style="width: 150px;"
                            @click="confirmRemoveInvite(item.row)">
                            <div class="w-2/12">
                              <feather-icon
                                icon="TrashIcon"
                                svgClasses="text-danger h-5 w-5 mb-1 stroke-current"/>
                            </div>
                            <div class="w-10/12">
                              <div class="flex bg-gray-200 float-left">
                                {{ $t('action.delete') }}
                              </div>
                            </div>
                          </div>
                        </vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </div>
                </template>
            </Grid>
          </div>
          <div class="font-bold flex justify-center sm:justify-end mt-4 sm:mt-0">
            <vs-button v-if="enableResendSelected"
              icon="send"
              class="mr-2"
              @click="resendInviteSelected">
              {{ $t('action.resend_invite') }}
            </vs-button>
            <vs-button v-if="enableResendAction"
              icon="send"
              type="border"
              class="mr-2"
              @click="confirmResendInviteAll">
              {{ $t('action.resend_invite_all') }}
            </vs-button>
            <vs-button v-if="enableResendAction"
              icon="send"
              color="warning"
              type="border"
              class="flex-grow-0 flex-shrink-0 h-auto"
              @click="resendInvitePending">
              {{ $t('action.resend_invite_pending') }}
            </vs-button>
          </div>
        </vx-card>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { formatScore } from '@/util/NumberUtil'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import EmailsInput from '@/components/EmailsInput'

import QuestionnaireInformation from '../questionnaires/events/QuestionnaireInformation.vue'


import QuestionnaireAnswerService from '@/services/api/QuestionnaireAnswerService'
import QuestionnaireService from '@/services/api/ContentQuestionnaireService'

export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
    QuestionnaireInformation,
    EmailsInput
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    questionnaireAnswerService: null,
    questionnaireService: null,
    questionnaire: null,
    selectedInvitedItems : []
  }),
  watch: {
    questionnaire() {
      this.clearData()
    },
  },
  computed: {
    answersResultsPath() {
      let path = ''
      if(this.questionnaire && this.questionnaire.id){
        path = `/${this.questionnaire.id}/results/grid`
      }else{
        path = `/0/results/grid` //bug fix in grid
      }

      return path
    },
    invitedPath() {
      let path = ''
      if(this.questionnaire && this.questionnaire.id){
        path = `/${this.questionnaire.id}/invited/grid`
      }else{
        path = `/0/invited/invited` //bug fix in grid
      }

      return path
    },
    validateForm() {
      return (this.questionnaire && this.questionnaire.id !== null)
    },
    enableTabQuestionnaire(){
      const acl = this.$acl
      return acl.hasPermission('backoffice.questionnaire')
    },
    enableTabInvited(){
      const acl = this.$acl
      return true //acl.hasPermission('backoffice.questionnaire.invited')
    },
    enableResendAction(){
      const acl = this.$acl
      const hasPermission = acl.hasPermission('backoffice.questionnaire.resend_invite')
      return (hasPermission && this.questionnairePublicated)
    },
    enableResendSelected(){
      return (this.enableResendAction && this.selectedInvitedItems.length > 0)
    },
    enableRemoveInviteAction(){
      const acl = this.$acl
      const hasPermission = acl.hasPermission('backoffice.questionnaire.remove_invite')
      return (hasPermission)
    },
    questionnairePublicated(){
      return this._.get(this.questionnaire, 'published_at') || false
    },
    invitedGrid() {
      return this.$refs['invitedGrid']
    },
  },
  beforeMount() {
    this.questionnaireAnswerService = QuestionnaireAnswerService.build(this.$vs)
    this.questionnaireService = QuestionnaireService.build(this.$vs)

  },
  mounted() {

  },

  methods: {
    loadDataQuestionnaireAnswersGrid() {
      if(this.questionnaire){
        this.$refs['answersGrid'].fetchGridData(true)
      }else{
        this.clearData()
      }
    },
    loadDataQuestionnaireInvitedGrid() {
      if(this.questionnaire){
        this.invitedGrid.fetchGridData(true)
      }else{
        this.clearData()
      }
    },
    loadData(){
      //Carrega o questionário
      this.loadDataQuestionnaireAnswersGrid()
      this.loadDataQuestionnaireInvitedGrid()

    },
    confirmForceFinish(data){
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-finalizar-essa-avaliacao')+`${'Participante: '+data.user_name+' <'+data.user_email+'>'}`,
        accept: () => { this.forceFinish(data)},
      })
    },
    forceFinish(data){
      this.$vs.loading()
      const answerId = data.id
      this.questionnaireAnswerService.finish(answerId,'backoffice').then(
        response => {
          this.notifySuccess(this.$vs,this.$t('questionario-finalizado'),5000,'center-top')
        },
        error => {
          this.notifyError(this.$vs, this.$t('erro-ao-processar-esta-requisicao'))
        }
      ).finally(() => {
        this.$vs.loading.close()
        this.loadDataQuestionnaireAnswersGrid()
      })
    },
    confirmResetAnswer(data) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('segunda-chance'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-quer-abrir-a-tentativa', [data.user_name]),
        accept: () => {this.resetAnswer(data.id)}
      })
    },
    resetAnswer(answerId) {
      this.$vs.loading()
      this.questionnaireAnswerService.reset(answerId).then(
        response => {
          this.$vs.loading.close()
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
        },
        error => {
         this.notifyError(this.$vs, this.$t('erro-ao-processar-esta-requisicao'))
        }
      ).finally(() => {
        this.$vs.loading.close()
        this.loadDataQuestionnaireAnswersGrid()
      })
    },
    confirmResetAnswerToBlank(data) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: 'Segunda chance - respostas zeradas!',
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-quer-abrir-a-tentativa', [data.user_name]),
        accept: () => {this.resetAnswerToBlank(data.id)}
      })
    },
    resetAnswerToBlank(answerId) {
      this.$vs.loading()
      this.questionnaireAnswerService.resetToBlank(answerId).then(
        response => {
          this.$vs.loading.close()
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
        },
        error => {
         this.notifyError(this.$vs, this.$t('erro-ao-processar-esta-requisicao'))
        }
      ).finally(() => {
        this.$vs.loading.close()
        this.loadDataQuestionnaireAnswersGrid()
      })
    },
    formatScore(val) {
      return formatScore(val)
    },
    clearData(){
      //Tab result
      this.$refs['answersGrid'].model.data= []
      this.$refs['answersGrid'].resetFilters()
      //Tab invited
      this.$refs['invitedGrid'].model.data= []
      this.$refs['invitedGrid'].resetFilters()

    },
    setDataFilter(){
      this.$refs['answersGrid'].fillCustomFilters([
        //{key: 'user', value: this.filter.user || ''},
      ])
    },
    isAdmin() {
      return isAdmin()
    },
    createdFormated(data){
      let fmt = ''
      if(data.display_created_at !== null){
        fmt = data.display_created_at
      }
      if(data.rewinded_at){
        fmt += `<br>Segunda Chance em: `
        fmt += this.$utils.format.date(data.rewinded_at)
      }
      return fmt
    },
    //IDs
    changedSelectionInvited(items) {
      this.selectedInvitedItems = items
    },
    resendInvite(item){
      let ids = []
      ids.push(item.id)

      this.resendSelectedInvitedItems(ids)
    },
    resendSelectedInvitedItems(ids){
      this.$vs.loading()
      this.questionnaireService.resendInvite(this.questionnaire.id, ids).then(
        response => {
          this.notifySuccess(this.$vs,this.$t('sucesso'),5000,'center-top')
        },
        error => {this.notifyError(this.$vs, this.$t('erro-ao-processar-esta-requisicao'))}
      ).finally(() => {this.$vs.loading.close()})
    },//End function
    resendInviteSelected(){
      this.resendSelectedInvitedItems(this.selectedInvitedItems)
    },
    confirmResendInviteAll(){
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-enviar-para-todos'),
        accept: () => { this.resendInviteAll()},
      })
    },
    resendInviteAll(){
      this.$vs.loading()
      this.questionnaireService.resendInviteAll(this.questionnaire.id).then(
        response => {
          this.notifySuccess(this.$vs,this.$t('sucesso'),5000,'center-top')
        },
        error => {this.notifyError(this.$vs, this.$t('erro-ao-processar-esta-requisicao'))}
      ).finally(() => {this.$vs.loading.close()})
    },
    resendInvitePending(){
      this.$vs.loading()
      this.questionnaireService.resendInvitePending(this.questionnaire.id).then(
        response => {
          this.notifySuccess(this.$vs,this.$t('sucesso'),5000,'center-top')
        },
        error => {this.notifyError(this.$vs, this.$t('erro-ao-processar-esta-requisicao'))}
      ).finally(() => {this.$vs.loading.close()})
    },
    formatCssRow(item){
      const status = this._.get(item, 'status')

      return (status === 'p' || status === 'o') ? 'text-warning' : ''
    },
    confirmRemoveInvite(data) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro'),
        accept: () => {this.removeInvite(data)}
      })
    },
    removeInvite(data){
      this.$vs.loading()
      this.questionnaireService.destroyEmailSent(this.questionnaire.id, data.id).then(
        response => {
          this.notifySuccess(this.$vs,this.$t('sucesso'),5000,'center-top')
          //Reload data
          this.loadDataQuestionnaireInvitedGrid()
        },
        error => {this.notifyError(this.$vs, this.$t('erro-ao-processar-esta-requisicao'))}
      ).finally(() => {this.$vs.loading.close()})
    },
    addEmails(list) {
      const formatted = list.map((email) => {
        return email.text
      })

      this.createEmailsSent({
        emails: formatted
      }).then(
        (data) => {
          const selection = this._.get(data, 'selection', []) || []
          const messages = this._.get(data, 'messages', []) || []

          if (messages.length > 0) {
            messages.forEach((message) => {
              this.notifyWarning(this.$vs, message, 0)
            })
          }
          console.log('selection...', selection)
          this.invitedGrid.selectMore(selection)
        }
      )
    },
    importStudents(students) {
      console.log('importStudents', students)
      const formatted = students.map((student) => {
        return {
          name: student.name,
          email: student.email,
          create_user: student.create_user
        }
      })

      this.createEmailsSent({
        users_to_import: formatted
      }).then(
        (data) => {
          const selection = this._.get(data, 'selection', []) || []
          const messages = this._.get(data, 'messages', []) || []

          if (messages.length > 0) {
            messages.forEach((message) => {
              this.notifyWarning(this.$vs, message, 0)
            })
          }

          this.loadDataQuestionnaireInvitedGrid()
          this.invitedGrid.selectMore(selection)
        }
      )
    },
    createEmailsSent(payload) {
      this.$vs.loading()
      return this.questionnaireService.createEmailsSent(this.questionnaire.id, {
        payload: payload
      }).then(
        (data) => {
          this.$refs['emailsInput'].clear()
          this.$vs.loading.close()

          this.loadDataQuestionnaireInvitedGrid()
          return data
        },
        (error) => {
          this.$vs.loading.close()
          console.error(error)
        }
      )
    },
  }
}
</script>

<style>
.vs-list--header{
  display: inline !important;
}

</style>
